'use client'

import { useSectionVisibility } from "@/hooks/use-section-visibility"
import { trackEvent } from "@/lib/tracking"
import { cn } from "@/lib/utils"
import { formatMargins } from "@/sanity/lib/format-margins"
import type { HeroSection } from "@/sanity/types"
import Image from "next/image"
import { useCallback } from "react"
import appStoreLogo from "../../../public/uploads/app-store-logo.png"
import playStoreLogo from "../../../public/uploads/play-store-logo.png"
import { AnimatedButtons, AnimatedHeading, AnimatedImage, AnimatedParagraph } from "../transitions/hero-animations"
import SanityImage from "../sanity-image"

export function Hero({
  sectionId,
  heading,
  content,
  appStoreLink,
  playStoreLink,
  heroMedia,
  margins,
}: Omit<HeroSection, '_type' | '_key'>) {
  // Track hero section visibility with high priority (lower threshold)
  const { ref } = useSectionVisibility({
    threshold: 0.05, // Only need 5% to be visible
    eventName: 'Hero Viewed',
    eventProps: {
      section_id: sectionId,
      heading,
    }
  })

  // Track app store link clicks with useCallback
  const handleAppStoreClick = useCallback(() => {
    trackEvent('Hero CTA Clicked', {
      section_id: sectionId,
      cta_type: 'app_store',
      url: appStoreLink
    })
  }, [sectionId, appStoreLink])

  // Track play store link clicks with useCallback
  const handlePlayStoreClick = useCallback(() => {
    trackEvent('Hero CTA Clicked', {
      section_id: sectionId,
      cta_type: 'play_store',
      url: playStoreLink
    })
  }, [sectionId, playStoreLink])

  // Track video/media interactions with useCallback
  const handleMediaInteraction = useCallback((type: string) => {
    trackEvent('Hero Media Interaction', {
      section_id: sectionId,
      media_type: type
    })
  }, [sectionId])

  return (
    <section 
      ref={ref}
      data-section="hero" 
      id={sectionId} 
      className={cn("container-padding py-12", formatMargins(margins))}
    >
      <div className="max-w-screen-xl mx-auto">
        <div className="max-w-6xl mx-auto relative z-10 pt-12 pb-16 md:pt-20 md:pb-32">
          <div className="flex flex-col lg:flex-row items-center gap-8 md:gap-12">
            <div className="flex-1 lg:max-w-[640px]">
              <AnimatedHeading className="text-5xl md:text-6xl lg:text-7xl font-bold mb-6">
                {heading}
              </AnimatedHeading>
              <AnimatedParagraph className="text-lg md:text-xl text-gray-600 mb-8">
                {content}
              </AnimatedParagraph>
              <AnimatedButtons className="flex flex-wrap justify-center lg:justify-start gap-4">
                {playStoreLink && (
                  <a 
                    href={playStoreLink} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="h-12 md:h-14"
                    onClick={handlePlayStoreClick}
                  >
                    <Image
                      src={playStoreLogo}
                      alt="Get it on Google Play"
                      width={168}
                      height={56}
                      className="h-auto w-auto"
                    />
                  </a>
                )}
                {appStoreLink && (
                  <a 
                    href={appStoreLink} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="h-12 md:h-14"
                    onClick={handleAppStoreClick}
                  >
                    <Image
                      src={appStoreLogo}
                      alt="Download on the App Store"
                      width={168}
                      height={56}
                      className="h-auto w-auto"
                    />
                  </a>
                )}
              </AnimatedButtons>
            </div>
            <div className="flex-1 flex justify-center lg:justify-end">
              <AnimatedImage className="w-full max-w-[300px]">
                {heroMedia?.mediaType === 'image' && heroMedia.image && (
                  <SanityImage
                    src={heroMedia.image}
                    alt={heroMedia.image.alt ?? heading ?? ''}
                    href={heroMedia.image.href}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 40vw"
                    className="w-full h-auto"
                    onClick={() => handleMediaInteraction('image')}
                  />
                )}
                {heroMedia?.mediaType === 'video' && heroMedia.video && (
                  <video
                    autoPlay={heroMedia.video.autoplay}
                    loop={heroMedia.video.loop}
                    muted={heroMedia.video.muted}
                    playsInline
                    className="w-full h-auto rounded-lg"
                    onClick={() => handleMediaInteraction('video')}
                  >
                    <source src={heroMedia.video.url} />
                  </video>
                )}
              </AnimatedImage>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
} 