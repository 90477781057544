'use client'

import { trackEvent, TrackEventName } from "@/lib/tracking"
import { useCallback, useMemo } from "react"

type EventTrackerProps = React.PropsWithChildren<{
  eventName: TrackEventName
  eventProps?: Record<string, unknown>
  onClick?: () => void
  className?: string
  /**
   * The HTML element to use as the wrapper
   * @default span
   */
  as?: React.ElementType
}>

/**
 * Client component that tracks interactions
 * This allows us to track clicks without making parent components client components
 */
export function EventTracker({ 
  children,
  eventName,
  eventProps = {},
  onClick,
  className = '',
  as: Element = 'span'
}: EventTrackerProps) {
  // Memoize the event props to avoid recreating the handler
  const memoizedEventProps = useMemo(() => eventProps, [eventProps])

  // Handle the click event
  const handleClick = useCallback(() => {
    // Track the event
    trackEvent(eventName, memoizedEventProps)

    // Call the original onClick if provided
    onClick?.()
  }, [eventName, memoizedEventProps, onClick])

  return (
    <Element onClick={handleClick} className={className}>
      {children}
    </Element>
  )
} 