'use client'

/**
 * @deprecated Use the VisibilityTracker component instead
 * This hook is kept for backward compatibility
 */

import { trackEvent, TrackEventName } from '@/lib/tracking'
import { useCallback, useEffect, useRef, useState } from 'react'

type SectionVisibilityOptions = {
  threshold?: number
  rootMargin?: string
  trackOnce?: boolean
  eventName: TrackEventName
  eventProps?: Record<string, unknown>
  /**
   * Delay in ms before firing the event after element becomes visible
   * Useful to avoid tracking during fast scrolling
   */
  trackDelay?: number
}

/**
 * Hook that tracks when an element becomes visible in the viewport
 * @deprecated Use the VisibilityTracker component instead
 */
export function useSectionVisibility<T extends HTMLElement = HTMLDivElement>({
  threshold = 0.1, // Visible when 10% of element is in view
  rootMargin = '0px',
  trackOnce = true,
  eventName,
  eventProps = {},
  trackDelay = 500
}: SectionVisibilityOptions) {
  const ref = useRef<T>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [hasTracked, setHasTracked] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  
  // Memoize the event props to prevent unnecessary callback recreations
  const memoizedEventProps = useRef(eventProps)
  
  // Update memoized event props when they change
  useEffect(() => {
    memoizedEventProps.current = eventProps
  }, [eventProps])

  // Callback for intersection observer
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      const newIsVisible = entry.isIntersecting
      
      setIsVisible(newIsVisible)
      
      // Track the event when element becomes visible
      if (newIsVisible && (!trackOnce || !hasTracked)) {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        
        // Set a timeout to ensure the user actually paused on this section
        timeoutRef.current = setTimeout(() => {
          trackEvent(eventName, memoizedEventProps.current)
          setHasTracked(true)
          timeoutRef.current = null
        }, trackDelay)
      } else if (!newIsVisible && timeoutRef.current) {
        // Cancel tracking if scrolled away quickly
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    },
    [eventName, hasTracked, trackOnce, trackDelay]
  )
  
  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])
  
  // Set up the intersection observer
  useEffect(() => {
    const element = ref.current
    if (!element) return
    
    const observer = new IntersectionObserver(
      handleIntersection,
      { threshold, rootMargin }
    )
    
    observer.observe(element)
    
    return () => {
      observer.disconnect()
    }
  }, [handleIntersection, rootMargin, threshold])
  
  return { ref, isVisible }
} 