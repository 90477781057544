'use client'

import { cn } from "@/lib/utils"

interface PricingButtonProps {
  buttonText?: string;
  buttonVariant: 'primary' | 'outline'
  highlighted?: boolean;
  className?: string;
  children?: React.ReactNode
  onClick?: () => void;
}

export const PricingCTAButton = ({
  buttonText,
  buttonVariant,
  highlighted,
  className,
  children,
  onClick
}: PricingButtonProps) => {
  const handleClick = () => {
    // Allow custom click handler if provided
    if (onClick) {
      onClick()
      return
    }
    
    // Default behavior
    if (buttonText === "Chat with sales") {
      const event = new CustomEvent('openContactForm')
      window.dispatchEvent(event)
      return
    }

    window.open("https://minimist.app.link/Bq1JPddiqNb", "_blank")
  }

  return (
    <button
      onClick={handleClick}
      className={cn(
        "block w-full py-3 px-6 rounded-lg mb-8 border text-center transition-colors",
        highlighted ? (
          'bg-[#0066FF] text-white border-[#0066FF] hover:bg-[#0052CC]'
        ) : buttonVariant === 'primary' ? (
          'bg-white text-[#0066FF] border-white hover:bg-white/90'
        ) : (
          'border-current hover:bg-neutral-50'
        ),
        className
      )}
    >
      {children || buttonText}
    </button>
  )
} 