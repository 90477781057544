import { cn } from "@/lib/utils"
import type { ContentMediaValue } from "@/sanity/schemas/fields/contentMedia"
import { HTMLAttributes } from "react"
import SanityImage from "../sanity-image"
import { AnimatedFeatureImage } from "../transitions/FeatureAnimations"

interface FeatureImageProps extends HTMLAttributes<HTMLDivElement> {
  media?: ContentMediaValue
  alt: string
  className?: string
  imageClassName?: string
}

const FeatureImage = ({
  media,
  alt,
  className,
  imageClassName,
  ...props
}: FeatureImageProps) => {
  if (!media) return null

  return (
    <div className={cn("flex-1", className)} {...props}>
      <AnimatedFeatureImage>
        <div className="absolute inset-0 bg-gradient-to-tr from-white/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        <div className="w-full h-full bg-white rounded-lg">
          {media.mediaType === 'image' && media.image && (
            <SanityImage
              src={media.image}
              alt={media.image.alt || alt}
              className={cn("w-full h-full object-cover rounded-lg", imageClassName)}
            />
          )}
          {media.mediaType === 'video' && media.video && (
            <video
              autoPlay={media.video.autoplay}
              loop={media.video.loop}
              muted={media.video.muted}
              playsInline
              className="w-full h-full object-cover rounded-lg"
              poster={media.video.poster?.asset._ref}
            >
              <source src={media.video.url} type="video/mp4" />
            </video>
          )}
        </div>
      </AnimatedFeatureImage>
    </div>
  )
}

export default FeatureImage