'use client'

import { trackEvent, TrackEventName } from '@/lib/tracking'
import { useEffect, useRef, useState } from 'react'

type VisibilityTrackerProps = React.PropsWithChildren<{
  eventName: TrackEventName
  eventProps?: Record<string, unknown>
  threshold?: number
  rootMargin?: string
  trackOnce?: boolean
  className?: string
  trackDelay?: number
  /**
   * The HTML element to use as the wrapper
   * @default div
   */
  as?: React.ElementType
  /**
   * Any other HTML attributes to pass to the element
   */
  [key: string]: any
}>

/**
 * Client component that tracks when content becomes visible.
 * Optimized for Next.js to allow wrapping server components.
 */
export function VisibilityTracker({
  children,
  eventName,
  eventProps = {},
  threshold = 0.1,
  rootMargin = '0px',
  trackOnce = true,
  className = '',
  trackDelay = 500,
  as: Element = 'div',
  ...otherProps
}: VisibilityTrackerProps) {
  const containerRef = useRef<HTMLElement>(null)
  const [hasTracked, setHasTracked] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  
  // Memoize event props to prevent unnecessary effect reruns
  const memoizedEventProps = useRef(eventProps)
  useEffect(() => {
    memoizedEventProps.current = eventProps
  }, [eventProps])

  // Set up intersection observer to track visibility
  useEffect(() => {
    // Skip if already tracked and trackOnce is true
    if (trackOnce && hasTracked) return
    
    const element = containerRef.current
    if (!element) return
    
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries
        
        if (entry.isIntersecting) {
          // Clear any existing timeout
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          
          // Set a timeout to ensure the user actually paused on this section
          timeoutRef.current = setTimeout(() => {
            if (!hasTracked || !trackOnce) {
              trackEvent(eventName, memoizedEventProps.current)
              setHasTracked(true)
            }
            timeoutRef.current = null
          }, trackDelay)
        } else if (timeoutRef.current) {
          // Cancel tracking if scrolled away quickly
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
      },
      { threshold, rootMargin }
    )
    
    observer.observe(element)
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      observer.disconnect()
    }
  }, [eventName, hasTracked, rootMargin, threshold, trackDelay, trackOnce])
  
  return (
    <Element 
      ref={containerRef} 
      className={className}
      {...otherProps}
    >
      {children}
    </Element>
  )
} 