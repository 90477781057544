'use client'

import { useSectionVisibility } from "@/hooks/use-section-visibility"
import { trackEvent } from "@/lib/tracking"
import { cn } from "@/lib/utils"
import { formatMargins } from "@/sanity/lib/format-margins"
import type { PricingSection } from "@/sanity/types"
import { useCallback } from "react"
import { PricingCTAButton } from "../ui/pricing-buttons"

export function Pricing({
  sectionId,
  heading,
  plans,
  margins
}: Omit<PricingSection, '_type' | '_key'>) {
  // Track section visibility
  const { ref } = useSectionVisibility({
    eventName: 'Pricing Viewed',
    eventProps: {
      section_id: sectionId,
      plan_count: plans?.length,
    }
  })

  // Track when a specific plan is clicked with useCallback
  const handlePlanClick = useCallback((planName: string | undefined, price: string | undefined) => {
    if (!planName) return
    
    trackEvent('Pricing Plan Selected', {
      section_id: sectionId,
      plan_name: planName,
      plan_price: price || 'unknown'
    })
  }, [sectionId])

  return (
    <section 
      ref={ref}
      data-section="pricing" 
      id={sectionId} 
      className={cn(!margins && "py-24", "scroll-mt-32", formatMargins(margins))}
    >
      <div className="container-padding">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-center text-4xl font-bold mb-16">{heading}</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {plans?.map((plan, index) => (
              <div
                key={`${index}-plan-${plan._key}`}
                className={cn(
                  "rounded-2xl p-8",
                  plan.highlighted
                    ? "bg-[#0066FF] text-white"
                    : "glass-panel"
                )}
              >
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <p className={cn(
                  "text-sm mb-6",
                  plan.highlighted ? "text-white/90" : "text-neutral-600"
                )}>
                  {plan.description}
                </p>
                <div className="mb-6">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  <span className={cn(
                    "text-sm ml-1",
                    plan.highlighted ? "text-white/90" : "text-neutral-600"
                  )}>
                    /{plan.period}
                  </span>
                </div>
                <PricingCTAButton
                  buttonVariant={plan.buttonVariant ?? 'primary'}
                  className="w-full mb-8"
                  onClick={() => handlePlanClick(plan.name, plan.price)}
                >
                  {plan.buttonText}
                </PricingCTAButton>
                <ul className="space-y-4">
                  {plan.features?.map((feature, i) => (
                    <li key={`${plan._key}-feature-${i}`} className="flex items-start gap-3">
                      <div className={cn(
                        "w-5 h-5 rounded-full shrink-0 mt-0.5 flex items-center justify-center",
                        plan.highlighted ? "bg-white/20" : "bg-[#0066FF]/10"
                      )}>
                        <svg
                          className={cn(
                            "w-3 h-3",
                            plan.highlighted ? "text-white" : "text-[#0066FF]"
                          )}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                          />
                        </svg>
                      </div>
                      <span className={cn(
                        "text-sm leading-tight",
                        plan.highlighted ? "text-white/90" : "text-neutral-600"
                      )}>
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
} 