'use client'

import { EventTracker } from "@/components/analytics/event-tracker"
import { cn } from "@/lib/utils"
import type { ContentMediaValue } from "@/sanity/schemas/fields/contentMedia"
import { HTMLAttributes } from "react"
import { LearnMoreButton, TryForFreeButton } from "./feature-buttons"
import FeatureImage from "./feature-image"
import FeaturePoint from "./feature-point"

interface FeatureCardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  points: string[];
  media?: ContentMediaValue;
  imageAlt: string;
  isReversed?: boolean;
  className?: string;
  contentClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  pointsClassName?: string;
  buttonsClassName?: string;
  featureIndex?: number;
  sectionId?: string;
}

const FeatureCard = ({
  title,
  description,
  points,
  media,
  imageAlt,
  isReversed = false,
  className,
  contentClassName,
  titleClassName,
  descriptionClassName,
  pointsClassName,
  buttonsClassName,
  featureIndex,
  sectionId,
  ...props
}: FeatureCardProps) => (
  <div
    data-name="feature-card"
    className={cn(
      "flex flex-col md:flex-row gap-12 items-center mb-24",
      isReversed && "md:flex-row-reverse",
      className
    )}
    {...props}
  >
    <div className={cn("flex-1", contentClassName)}>
      <h3 className={cn("text-2xl font-bold mb-4", titleClassName)}>
        {title}
      </h3>
      <p className={cn("text-neutral-600 mb-6", descriptionClassName)}>
        {description}
      </p>
      <ul className={cn("space-y-4", pointsClassName)}>
        {points.map((point, index) => (
          <FeaturePoint key={point} index={index}>
            {point}
          </FeaturePoint>
        ))}
      </ul>
      <div className={cn("mt-8 flex gap-4", buttonsClassName)}>
        <EventTracker
          eventName="Feature Interaction"
          eventProps={{
            feature_title: title,
            feature_index: featureIndex,
            section_id: sectionId,
            interaction_type: 'try_free_button'
          }}
        >
          <TryForFreeButton />
        </EventTracker>
        
        <EventTracker
          eventName="Feature Interaction"
          eventProps={{
            feature_title: title,
            feature_index: featureIndex,
            section_id: sectionId,
            interaction_type: 'learn_more_button'
          }}
        >
          <LearnMoreButton />
        </EventTracker>
      </div>
    </div>
    <FeatureImage media={media} alt={imageAlt} />
  </div>
)

export default FeatureCard