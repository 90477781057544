import { cn } from "@/lib/utils"
import { HTMLAttributes } from "react"
import { AnimatedFeaturePoint } from "../transitions/FeatureAnimations"

interface FeaturePointProps extends HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  index?: number;
  iconClassName?: string;
  iconContainerClassName?: string;
}

const FeaturePoint = ({
  children,
  index = 0,
  iconClassName,
  iconContainerClassName,
  ...props
}: FeaturePointProps) => (
  <li {...props}>
    <AnimatedFeaturePoint index={index}>
      <div className={cn("w-5 h-5 rounded-full bg-accent-purple/10 flex items-center justify-center", iconContainerClassName)}>
        <svg
          className={cn("w-3 h-3 text-accent-purple", iconClassName)}
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
          />
        </svg>
      </div>
      {children}
    </AnimatedFeaturePoint>
  </li>
)

export default FeaturePoint